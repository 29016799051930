<template>
  <i-container v-if="response" class="_margin-bottom-6 bock--container">
    <i-row>
      <i-column
        xs="12"
        sm="12"
        md="6"
        v-for="(item, index) in response.data.body"
        :key="`piece-${index}`"
        class="tile"
      >
        <div
          @click="
            $router.push({
              name: 'Mural',
              params: { id: slugify(item.primary.title1) },
            })
          "
          class="bock--hover"
        >
          <prismic-image
            v-if="item.primary.image.wide"
            :field="item.primary.image.wide"
            class="image -responsive"
          />
          <div
            class="
              _padding-top-0
              _margin-bottom-1
              _display-flex
              _justify-content-space-between
            "
          >
            <div>
              <small class="_text-muted _font-weight-semibold bock--trunc">{{
                item.primary.title1
              }}</small>
            </div>
            <div>
              <small class="_text-muted _font-weight-semibold bock--trunc">{{
                item.primary.year
              }}</small>
              <small class="_text-muted _font-weight-semibold bock--trunc"
                >&nbsp; {{ item.primary.location }}</small
              >
            </div>
          </div>
        </div>
      </i-column>
    </i-row>
    <!-- <pre v-if="response" v-html="JSON.stringify(response, null, 2)"></pre> -->
  </i-container>
</template>

<script>
export default {
  name: "Murals",
  data() {
    return {
      response: null,
    };
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle("murals");
      this.response = response;
    },
    slugify(string) {
      const a =
        "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
      const b =
        "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
      const p = new RegExp(a.split("").join("|"), "g");
      return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
    },
  },
  created() {
    this.getContent();
  },
};
</script>
